/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot re-loadable.
 */

import SampleModuleReducers from './SampleModule/reducers';

import MMModuleReducers from './MMModule/reducers';

import MMProfileReducers from './MMProfile/reducers';

import MMLifeEventCategoriesReducers from './MMLifeEventCategories/reducers';

import MMLifeEventsReducers from './MMLifeEvents/reducers';

import MMNoticesReducers from './MMNotices/reducers';

import MMOnlineRenewalReducers from './MMOnlineRenewal/reducers';

import MMICAMReducers from './MMICAM/reducers';

/**
 * Creates the main reducer which combines all the reducers from the modules
 * and any provided reducers supplied from the wrapping application.
 *
 * Provided reducers are ignored where there is a clash in naming.
 *
 * @namespace UAReduxReducers
 */
export default {
  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UASampleModule: SampleModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMModule: MMModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMProfile: MMProfileReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMLifeEventCategories: MMLifeEventCategoriesReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMLifeEvents: MMLifeEventsReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMNotices: MMNoticesReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMOnlineRenewal: MMOnlineRenewalReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAMMICAM: MMICAMReducers.reducers,
};
