/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the MMUALifeEventContext
 *
 * @class MMUALifeEventContext
 */
class MMUALifeEventContext {
  /**
   * Creates an instance of MMUALifeEventContext.
   * @param {*} json
   * @memberof MMUALifeEventContext
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventContextId = json.lifeEventContextId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.common = json.common;

      // string
      this.imageName = json.imageName;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.additionalInfo = json.additionalInfo;

      // string
      this.submissionText = json.submissionText;

      // string
      this.launchDescription = json.launchDescription;

      // boolean
      this.isCitizenConsentEnabled = json.isCitizenConsentEnabled;

      // boolean
      this.isCitizenConsentOneChoiceEnabled = json.isCitizenConsentOneChoiceEnabled;

      // string
      this.imageAltText = json.imageAltText;

      // string
      this.integratedCaseID = json.integratedCaseID;

      // string
      this.applicationEffectiveDate = json.applicationEffectiveDate;

      // string
      this.primaryApplicantName = json.primaryApplicantName;

      // string
      this.caseReference = json.caseReference;

      // string
      this.onlineRenewalDSID = json.onlineRenewalDSID;
    }
  }
}

/**
 * Models the MMUALifeEventCategory
 *
 * @class MMUALifeEventCategory
 */
class MMUALifeEventCategory {
  /**
   * Creates an instance of MMUALifeEventCategory.
   * @param {*} json
   * @memberof MMUALifeEventCategory
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.lifeEventCategoryId = json.lifeEventCategoryId;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.imageName = json.imageName;

      // string
      this.imageAltText = json.imageAltText;

      // array - MMUALifeEventContext
      if (json.lifeEventContexts) {
        this.lifeEventContexts = json.lifeEventContexts.map(
          object => new MMUALifeEventContext(object)
        );
      } else if (json.constructor === Array) {
        this.lifeEventContexts = json.map(object => new MMUALifeEventContext(object));
      }
    }
  }
}

/**
 * Models the MMUAListLifeEventCategories
 *
 * @class MMUAListLifeEventCategories
 */
class MMUAListLifeEventCategories {
  /**
   * Creates an instance of MMUAListLifeEventCategories.
   * @param {*} json
   * @memberof MMUAListLifeEventCategories
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.openNonMAGIICExist = json.openNonMAGIICExist;

      // array - MMUALifeEventCategory
      if (json.data) {
        this.data = json.data.map(object => new MMUALifeEventCategory(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new MMUALifeEventCategory(object));
      }
    }
  }
}

export default {
  MMUALifeEventContext,

  MMUALifeEventCategory,

  MMUAListLifeEventCategories,
};
