/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_MM_MEDICAID_ID_SEARCH,
  FETCH_MM_ACCOUNT_LINK,
  FETCH_MM_SEND_LOGOUT_REQUEST,
} from './actionTypes';

import {
  fetchMmMedicaidIDSearchUtil,
  fetchMmAccountLinkUtil,
  fetchMmSendLogoutRequestUtil,
} from './utils';

/**
 * Contains the action creator functions that update the MMICAM slice of the store.
 *
 * @namespace MMICAMActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/medicaidIDSearch
   *
   * @param {string} medicaidID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMICAMActions
   */
  static fetchMmMedicaidIDSearch = (dispatch, medicaidID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmMedicaidIDSearchUtil.bind(null, medicaidID),

      FETCH_MM_MEDICAID_ID_SEARCH,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmMedicaidIDSearch.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmMedicaidIDSearch = (dispatch, data) => {
    const json = {
      type: FETCH_MM_MEDICAID_ID_SEARCH.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmMedicaidIDSearch.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmMedicaidIDSearchError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_MEDICAID_ID_SEARCH.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/accountLink
   *
   * @param {string} sessionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMICAMActions
   */
  static fetchMmAccountLink = (dispatch, sessionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmAccountLinkUtil.bind(null, sessionID),

      FETCH_MM_ACCOUNT_LINK,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmAccountLink.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmAccountLink = (dispatch, data) => {
    const json = {
      type: FETCH_MM_ACCOUNT_LINK.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmAccountLink.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmAccountLinkError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_ACCOUNT_LINK.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/send_logout_request
   *
   * @param {string} sessionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMICAMActions
   */
  static fetchMmSendLogoutRequest = (dispatch, sessionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmSendLogoutRequestUtil.bind(null, sessionID),

      FETCH_MM_SEND_LOGOUT_REQUEST,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmSendLogoutRequest.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmSendLogoutRequest = (dispatch, data) => {
    const json = {
      type: FETCH_MM_SEND_LOGOUT_REQUEST.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmSendLogoutRequest.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMICAMActions
   */
  static resetFetchMmSendLogoutRequestError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_SEND_LOGOUT_REQUEST.failureAction);
  };
}
