/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'MMOnlineRenewal' store object.
 * @namespace MMOnlineRenewalSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAMMOnlineRenewal';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmOnlineRenewalRidpForm = state =>
    state[selectors.moduleIdentifier].postMmOnlineRenewalRidpForm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingPostMmOnlineRenewalRidpForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmOnlineRenewalRidpForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmOnlineRenewalRidpFormError = state =>
    state[selectors.moduleIdentifier].postMmOnlineRenewalRidpFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmSubmitOnlineRenewalRidp = state =>
    state[selectors.moduleIdentifier].postMmSubmitOnlineRenewalRidp.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingPostMmSubmitOnlineRenewalRidp = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmSubmitOnlineRenewalRidp.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmSubmitOnlineRenewalRidpError = state =>
    state[selectors.moduleIdentifier].postMmSubmitOnlineRenewalRidpError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static fetchMmOnlineCategories = state =>
    state[selectors.moduleIdentifier].fetchMmOnlineCategories.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingFetchMmOnlineCategories = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmOnlineCategories.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static fetchMmOnlineCategoriesError = state =>
    state[selectors.moduleIdentifier].fetchMmOnlineCategoriesError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmIsRenewalScript = state =>
    state[selectors.moduleIdentifier].postMmIsRenewalScript.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingPostMmIsRenewalScript = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmIsRenewalScript.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmIsRenewalScriptError = state =>
    state[selectors.moduleIdentifier].postMmIsRenewalScriptError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static fetchMmIsRenewalToDoDisplay = state =>
    state[selectors.moduleIdentifier].fetchMmIsRenewalToDoDisplay.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingFetchMmIsRenewalToDoDisplay = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmIsRenewalToDoDisplay.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static fetchMmIsRenewalToDoDisplayError = state =>
    state[selectors.moduleIdentifier].fetchMmIsRenewalToDoDisplayError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmStoreOnlineRenewal = state =>
    state[selectors.moduleIdentifier].postMmStoreOnlineRenewal.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static isFetchingPostMmStoreOnlineRenewal = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmStoreOnlineRenewal.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMOnlineRenewalSelectors
   */
  static postMmStoreOnlineRenewalError = state =>
    state[selectors.moduleIdentifier].postMmStoreOnlineRenewalError.payload;
}
