/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'MMLifeEvents' store object.
 * @namespace MMLifeEventsSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAMMLifeEvents';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static postMmLifeEventsForm = state =>
    state[selectors.moduleIdentifier].postMmLifeEventsForm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static isFetchingPostMmLifeEventsForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmLifeEventsForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static postMmLifeEventsFormError = state =>
    state[selectors.moduleIdentifier].postMmLifeEventsFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static postMmLifeEventsFormSubmit = state =>
    state[selectors.moduleIdentifier].postMmLifeEventsFormSubmit.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static isFetchingPostMmLifeEventsFormSubmit = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmLifeEventsFormSubmit.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMLifeEventsSelectors
   */
  static postMmLifeEventsFormSubmitError = state =>
    state[selectors.moduleIdentifier].postMmLifeEventsFormSubmitError.payload;
}
