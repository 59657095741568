/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMOnlineRenewal.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MM_ONLINE_RENEWAL_RIDP_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMONLINERENEWAL/POST_MM_ONLINE_RENEWAL_RIDP_FORM`
);

const POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMONLINERENEWAL/POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP`
);

const FETCH_MM_ONLINE_CATEGORIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMONLINERENEWAL/FETCH_MM_ONLINE_CATEGORIES`
);

const POST_MM_IS_RENEWAL_SCRIPT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMONLINERENEWAL/POST_MM_IS_RENEWAL_SCRIPT`
);

const FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMONLINERENEWAL/FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY`
);

const POST_MM_STORE_ONLINE_RENEWAL = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMONLINERENEWAL/POST_MM_STORE_ONLINE_RENEWAL`
);

export {
  POST_MM_ONLINE_RENEWAL_RIDP_FORM,
  POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP,
  FETCH_MM_ONLINE_CATEGORIES,
  POST_MM_IS_RENEWAL_SCRIPT,
  FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY,
  POST_MM_STORE_ONLINE_RENEWAL,
};
