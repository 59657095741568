/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the MMExternalUserLinkingResult
 *
 * @class MMExternalUserLinkingResult
 */
class MMExternalUserLinkingResult {
  /**
   * Creates an instance of MMExternalUserLinkingResult.
   * @param {*} json
   * @memberof MMExternalUserLinkingResult
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pageName = json.pageName;

      // string
      this.sessionID = json.sessionID;
    }
  }
}

/**
 * Models the MMIDPLogoutResponseDetails
 *
 * @class MMIDPLogoutResponseDetails
 */
class MMIDPLogoutResponseDetails {
  /**
   * Creates an instance of MMIDPLogoutResponseDetails.
   * @param {*} json
   * @memberof MMIDPLogoutResponseDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.idpLogoutStatusInd = json.idpLogoutStatusInd;
    }
  }
}

export default {
  MMExternalUserLinkingResult,

  MMIDPLogoutResponseDetails,
};
