/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_MM_PROFILE } from './actionTypes';

import { fetchMmProfileUtil } from './utils';

/**
 * Contains the action creator functions that update the MMProfile slice of the store.
 *
 * @namespace MMProfileActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/profile
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMProfileActions
   */
  static fetchMmProfile = (dispatch, callback) => {
    ReduxUtils.generateAction(dispatch, fetchMmProfileUtil, FETCH_MM_PROFILE, callback);
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmProfile.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMProfileActions
   */
  static resetFetchMmProfile = (dispatch, data) => {
    const json = {
      type: FETCH_MM_PROFILE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmProfile.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMProfileActions
   */
  static resetFetchMmProfileError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_PROFILE.failureAction);
  };
}
