/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { POST_MM_LIFE_EVENTS_FORM, POST_MM_LIFE_EVENTS_FORM_SUBMIT } from './actionTypes';

/**
 * Updates the details in the 'MMLifeEvents' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_LIFE_EVENTS_FORM);

/**
 * Updates the indicator of creation of details in the 'MMLifeEvents' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_LIFE_EVENTS_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmLifeEventsFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_LIFE_EVENTS_FORM);

/**
 * Updates the details in the 'MMLifeEvents' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmLifeEventsFormSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_LIFE_EVENTS_FORM_SUBMIT);

/**
 * Updates the indicator of creation of details in the 'MMLifeEvents' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmLifeEventsFormSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_LIFE_EVENTS_FORM_SUBMIT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmLifeEventsFormSubmitErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_LIFE_EVENTS_FORM_SUBMIT);

const reducers = combineReducers({
  postMmLifeEventsForm: postMmLifeEventsFormReducer,

  isFetchingPostMmLifeEventsForm: isFetchingPostMmLifeEventsFormReducer,
  postMmLifeEventsFormError: postMmLifeEventsFormErrorReducer,

  postMmLifeEventsFormSubmit: postMmLifeEventsFormSubmitReducer,

  isFetchingPostMmLifeEventsFormSubmit: isFetchingPostMmLifeEventsFormSubmitReducer,
  postMmLifeEventsFormSubmitError: postMmLifeEventsFormSubmitErrorReducer,
});

export default { reducers };
