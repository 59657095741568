/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'MMICAM' store object.
 * @namespace MMICAMSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAMMICAM';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmMedicaidIDSearch = state =>
    state[selectors.moduleIdentifier].fetchMmMedicaidIDSearch.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static isFetchingFetchMmMedicaidIDSearch = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmMedicaidIDSearch.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmMedicaidIDSearchError = state =>
    state[selectors.moduleIdentifier].fetchMmMedicaidIDSearchError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmAccountLink = state => state[selectors.moduleIdentifier].fetchMmAccountLink.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static isFetchingFetchMmAccountLink = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmAccountLink.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmAccountLinkError = state =>
    state[selectors.moduleIdentifier].fetchMmAccountLinkError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmSendLogoutRequest = state =>
    state[selectors.moduleIdentifier].fetchMmSendLogoutRequest.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static isFetchingFetchMmSendLogoutRequest = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmSendLogoutRequest.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMICAMSelectors
   */
  static fetchMmSendLogoutRequestError = state =>
    state[selectors.moduleIdentifier].fetchMmSendLogoutRequestError.payload;
}
