/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMICAM.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_MM_MEDICAID_ID_SEARCH = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMICAM/FETCH_MM_MEDICAID_ID_SEARCH`
);

const FETCH_MM_ACCOUNT_LINK = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMICAM/FETCH_MM_ACCOUNT_LINK`
);

const FETCH_MM_SEND_LOGOUT_REQUEST = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMICAM/FETCH_MM_SEND_LOGOUT_REQUEST`
);

export { FETCH_MM_MEDICAID_ID_SEARCH, FETCH_MM_ACCOUNT_LINK, FETCH_MM_SEND_LOGOUT_REQUEST };
