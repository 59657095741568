/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_MOTIVATION_TYPE,
  GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID,
  POST_MOTIVATION_SETUP_AND_START,
  POST_MOTIVATION_START,
  POST_MOTIVATION_SETUP,
  FETCH_MOTIVATIONS,
  POST_MOTIVATION_RESUME,
  GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID,
  POST_MM_MOTIVATION_CHANGE_OWNERSHIP,
  CREATE_APPLICATION_FORM,
  POST_HCR_MOTIVATION_SIGNANDSUBMIT,
  DELETE_MM_MOTIVATION_BY_MOTIVATION_ID,
  POST_MM_APPLICATION_FORM,
  FETCH_MM_VERIFICATIONS,
  POST_DBS_MM_FILES,
  POST_MM_RESETPASSWORD,
  POST_MM_HCR_TO_CGIS,
  POST_MM_LAUNCH_INTAKE_MOTIVATION,
  POST_MM_APPLYING_FOR_NON_MAGI,
  POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK,
  POST_MM_IS_MOTIVATION_SCRIPT,
  FETCH_MM_DRAFT_APPLICATIONS,
  FETCH_MM_SYSTEM_CONFIGURATIONS,
  FETCH_MM_VALIDATE_SESSION,
  POST_MM_VERIFICATIONS_LINK_FILE,
  GET_MM_VERIFICATIONS_BY_VERIFICATION_ID,
  FETCH_MM_SUBMITTED_APPLICATIONS,
  POST_MM_APPLICATIONS,
  GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID,
  SET_SELECTED_APPLICATION_TYPE,
} from './actionTypes';

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMotivationTypeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MOTIVATION_TYPE);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMotivationTypeReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MOTIVATION_TYPE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMotivationTypeErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MOTIVATION_TYPE);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMotivationTypeIegByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMotivationTypeIegByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMotivationTypeIegByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupAndStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_START);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_START);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMotivationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MOTIVATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMotivationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MOTIVATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMotivationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MOTIVATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationResumeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_RESUME);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationResumeReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_RESUME);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationResumeErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_RESUME);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMotivationIegByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMotivationIegByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMotivationIegByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmMotivationChangeOwnershipReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_MOTIVATION_CHANGE_OWNERSHIP);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmMotivationChangeOwnershipReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_MOTIVATION_CHANGE_OWNERSHIP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmMotivationChangeOwnershipErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_MOTIVATION_CHANGE_OWNERSHIP);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostApplicationFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, CREATE_APPLICATION_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const createApplicationFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, CREATE_APPLICATION_FORM);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postHcrMotivationSignandsubmitReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_HCR_MOTIVATION_SIGNANDSUBMIT);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostHcrMotivationSignandsubmitReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_HCR_MOTIVATION_SIGNANDSUBMIT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postHcrMotivationSignandsubmitErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_HCR_MOTIVATION_SIGNANDSUBMIT);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const deleteMmMotivationByMotivationIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, DELETE_MM_MOTIVATION_BY_MOTIVATION_ID);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingDeleteMmMotivationByMotivationIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, DELETE_MM_MOTIVATION_BY_MOTIVATION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const deleteMmMotivationByMotivationIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, DELETE_MM_MOTIVATION_BY_MOTIVATION_ID);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmApplicationFormReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_APPLICATION_FORM);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmApplicationFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_APPLICATION_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmApplicationFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_APPLICATION_FORM);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmVerificationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_VERIFICATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmVerificationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_VERIFICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmVerificationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_VERIFICATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postDbsMmFilesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_DBS_MM_FILES);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostDbsMmFilesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_DBS_MM_FILES);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postDbsMmFilesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_DBS_MM_FILES);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmResetpasswordReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_RESETPASSWORD);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmResetpasswordReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_RESETPASSWORD);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmResetpasswordErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_RESETPASSWORD);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmHcrToCgisReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_HCR_TO_CGIS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmHcrToCgisReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_HCR_TO_CGIS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmHcrToCgisErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_HCR_TO_CGIS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmLaunchIntakeMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_LAUNCH_INTAKE_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmLaunchIntakeMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_LAUNCH_INTAKE_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmLaunchIntakeMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_LAUNCH_INTAKE_MOTIVATION);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmApplyingForNonMagiReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_APPLYING_FOR_NON_MAGI);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmApplyingForNonMagiReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_APPLYING_FOR_NON_MAGI);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmApplyingForNonMagiErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_APPLYING_FOR_NON_MAGI);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmVerificationsCreateCaseworkerTaskReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmVerificationsCreateCaseworkerTaskReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmVerificationsCreateCaseworkerTaskErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmIsMotivationScriptReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_IS_MOTIVATION_SCRIPT);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmIsMotivationScriptReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_IS_MOTIVATION_SCRIPT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmIsMotivationScriptErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_IS_MOTIVATION_SCRIPT);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_DRAFT_APPLICATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmDraftApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_DRAFT_APPLICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmDraftApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_DRAFT_APPLICATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmSystemConfigurationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_SYSTEM_CONFIGURATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmSystemConfigurationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_SYSTEM_CONFIGURATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmSystemConfigurationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_SYSTEM_CONFIGURATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmValidateSessionReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_VALIDATE_SESSION);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmValidateSessionReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_VALIDATE_SESSION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmValidateSessionErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_VALIDATE_SESSION);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmVerificationsLinkFileReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_VERIFICATIONS_LINK_FILE);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmVerificationsLinkFileReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_VERIFICATIONS_LINK_FILE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmVerificationsLinkFileErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_VERIFICATIONS_LINK_FILE);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMmVerificationsByVerificationIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MM_VERIFICATIONS_BY_VERIFICATION_ID);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMmVerificationsByVerificationIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_MM_VERIFICATIONS_BY_VERIFICATION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMmVerificationsByVerificationIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_MM_VERIFICATIONS_BY_VERIFICATION_ID);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmSubmittedApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_SUBMITTED_APPLICATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmSubmittedApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_SUBMITTED_APPLICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmSubmittedApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_SUBMITTED_APPLICATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_APPLICATIONS);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_APPLICATIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_APPLICATIONS);

/**
 * Updates the details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMmFormDetailsByApplicationFormIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the indicator of creation of details in the 'MMModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMmFormDetailsByApplicationFormIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMmFormDetailsByApplicationFormIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID);

/**
 * Updates the store with the new data.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const setSelectedApplicationTypeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, { successAction: SET_SELECTED_APPLICATION_TYPE });

const reducers = combineReducers({
  fetchMotivationType: fetchMotivationTypeReducer,

  isFetchingFetchMotivationType: isFetchingFetchMotivationTypeReducer,
  fetchMotivationTypeError: fetchMotivationTypeErrorReducer,

  getMotivationTypeIegByIegExecutionID: getMotivationTypeIegByIegExecutionIDReducer,

  isFetchingGetMotivationTypeIegByIegExecutionID:
    isFetchingGetMotivationTypeIegByIegExecutionIDReducer,
  getMotivationTypeIegByIegExecutionIDError: getMotivationTypeIegByIegExecutionIDErrorReducer,

  postMotivationSetupAndStart: postMotivationSetupAndStartReducer,

  isFetchingPostMotivationSetupAndStart: isFetchingPostMotivationSetupAndStartReducer,
  postMotivationSetupAndStartError: postMotivationSetupAndStartErrorReducer,

  postMotivationStart: postMotivationStartReducer,

  isFetchingPostMotivationStart: isFetchingPostMotivationStartReducer,
  postMotivationStartError: postMotivationStartErrorReducer,

  postMotivationSetup: postMotivationSetupReducer,

  isFetchingPostMotivationSetup: isFetchingPostMotivationSetupReducer,
  postMotivationSetupError: postMotivationSetupErrorReducer,

  fetchMotivations: fetchMotivationsReducer,

  isFetchingFetchMotivations: isFetchingFetchMotivationsReducer,
  fetchMotivationsError: fetchMotivationsErrorReducer,

  postMotivationResume: postMotivationResumeReducer,

  isFetchingPostMotivationResume: isFetchingPostMotivationResumeReducer,
  postMotivationResumeError: postMotivationResumeErrorReducer,

  getMotivationIegByIegExecutionID: getMotivationIegByIegExecutionIDReducer,

  isFetchingGetMotivationIegByIegExecutionID: isFetchingGetMotivationIegByIegExecutionIDReducer,
  getMotivationIegByIegExecutionIDError: getMotivationIegByIegExecutionIDErrorReducer,

  postMmMotivationChangeOwnership: postMmMotivationChangeOwnershipReducer,

  isFetchingPostMmMotivationChangeOwnership: isFetchingPostMmMotivationChangeOwnershipReducer,
  postMmMotivationChangeOwnershipError: postMmMotivationChangeOwnershipErrorReducer,

  isFetchingPostApplicationForm: isFetchingPostApplicationFormReducer,
  createApplicationFormError: createApplicationFormErrorReducer,

  postHcrMotivationSignandsubmit: postHcrMotivationSignandsubmitReducer,

  isFetchingPostHcrMotivationSignandsubmit: isFetchingPostHcrMotivationSignandsubmitReducer,
  postHcrMotivationSignandsubmitError: postHcrMotivationSignandsubmitErrorReducer,

  deleteMmMotivationByMotivationId: deleteMmMotivationByMotivationIdReducer,

  isFetchingDeleteMmMotivationByMotivationId: isFetchingDeleteMmMotivationByMotivationIdReducer,
  deleteMmMotivationByMotivationIdError: deleteMmMotivationByMotivationIdErrorReducer,

  postMmApplicationForm: postMmApplicationFormReducer,

  isFetchingPostMmApplicationForm: isFetchingPostMmApplicationFormReducer,
  postMmApplicationFormError: postMmApplicationFormErrorReducer,

  fetchMmVerifications: fetchMmVerificationsReducer,

  isFetchingFetchMmVerifications: isFetchingFetchMmVerificationsReducer,
  fetchMmVerificationsError: fetchMmVerificationsErrorReducer,

  postDbsMmFiles: postDbsMmFilesReducer,

  isFetchingPostDbsMmFiles: isFetchingPostDbsMmFilesReducer,
  postDbsMmFilesError: postDbsMmFilesErrorReducer,

  postMmResetpassword: postMmResetpasswordReducer,

  isFetchingPostMmResetpassword: isFetchingPostMmResetpasswordReducer,
  postMmResetpasswordError: postMmResetpasswordErrorReducer,

  postMmHcrToCgis: postMmHcrToCgisReducer,

  isFetchingPostMmHcrToCgis: isFetchingPostMmHcrToCgisReducer,
  postMmHcrToCgisError: postMmHcrToCgisErrorReducer,

  postMmLaunchIntakeMotivation: postMmLaunchIntakeMotivationReducer,

  isFetchingPostMmLaunchIntakeMotivation: isFetchingPostMmLaunchIntakeMotivationReducer,
  postMmLaunchIntakeMotivationError: postMmLaunchIntakeMotivationErrorReducer,

  postMmApplyingForNonMagi: postMmApplyingForNonMagiReducer,

  isFetchingPostMmApplyingForNonMagi: isFetchingPostMmApplyingForNonMagiReducer,
  postMmApplyingForNonMagiError: postMmApplyingForNonMagiErrorReducer,

  postMmVerificationsCreateCaseworkerTask: postMmVerificationsCreateCaseworkerTaskReducer,

  isFetchingPostMmVerificationsCreateCaseworkerTask:
    isFetchingPostMmVerificationsCreateCaseworkerTaskReducer,
  postMmVerificationsCreateCaseworkerTaskError: postMmVerificationsCreateCaseworkerTaskErrorReducer,

  postMmIsMotivationScript: postMmIsMotivationScriptReducer,

  isFetchingPostMmIsMotivationScript: isFetchingPostMmIsMotivationScriptReducer,
  postMmIsMotivationScriptError: postMmIsMotivationScriptErrorReducer,

  fetchMmDraftApplications: fetchMmDraftApplicationsReducer,

  isFetchingFetchMmDraftApplications: isFetchingFetchMmDraftApplicationsReducer,
  fetchMmDraftApplicationsError: fetchMmDraftApplicationsErrorReducer,

  fetchMmSystemConfigurations: fetchMmSystemConfigurationsReducer,

  isFetchingFetchMmSystemConfigurations: isFetchingFetchMmSystemConfigurationsReducer,
  fetchMmSystemConfigurationsError: fetchMmSystemConfigurationsErrorReducer,

  fetchMmValidateSession: fetchMmValidateSessionReducer,

  isFetchingFetchMmValidateSession: isFetchingFetchMmValidateSessionReducer,
  fetchMmValidateSessionError: fetchMmValidateSessionErrorReducer,

  postMmVerificationsLinkFile: postMmVerificationsLinkFileReducer,

  isFetchingPostMmVerificationsLinkFile: isFetchingPostMmVerificationsLinkFileReducer,
  postMmVerificationsLinkFileError: postMmVerificationsLinkFileErrorReducer,

  getMmVerificationsByVerificationId: getMmVerificationsByVerificationIdReducer,

  isFetchingGetMmVerificationsByVerificationId: isFetchingGetMmVerificationsByVerificationIdReducer,
  getMmVerificationsByVerificationIdError: getMmVerificationsByVerificationIdErrorReducer,

  fetchMmSubmittedApplications: fetchMmSubmittedApplicationsReducer,

  isFetchingFetchMmSubmittedApplications: isFetchingFetchMmSubmittedApplicationsReducer,
  fetchMmSubmittedApplicationsError: fetchMmSubmittedApplicationsErrorReducer,

  postMmApplications: postMmApplicationsReducer,

  isFetchingPostMmApplications: isFetchingPostMmApplicationsReducer,
  postMmApplicationsError: postMmApplicationsErrorReducer,

  getMmFormDetailsByApplicationFormId: getMmFormDetailsByApplicationFormIdReducer,

  isFetchingGetMmFormDetailsByApplicationFormId:
    isFetchingGetMmFormDetailsByApplicationFormIdReducer,
  getMmFormDetailsByApplicationFormIdError: getMmFormDetailsByApplicationFormIdErrorReducer,

  selectedApplicationType: setSelectedApplicationTypeReducer,
});

export default { reducers };
